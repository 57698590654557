import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';

import { Radar } from 'react-chartjs-2';
import { Chart } from "react-chartjs-2";


import "./../components/conversational-index/france/ci-resultat/ci-donuts.scss";

import CiDonutsResultat from "../components/conversational-index/usa/ci-resultat-donuts/ci-resultat-donuts"
import CiCtaResultat from "../components/conversational-index/usa/ci-resultat-cta/ci-resultat-cta"

import Footer from "./../components/conversational-index/usa/ci-footer/ci-footer";

import SEO from "../components/seo";

class ResultatPageUs extends Component {


    render() {

        Chart.defaults.global.legend.display = false;

        let resultats;
        let finalResultats = [];
        let ci_global = 0;

        let classes = "";

        let ci_p1, ci_p2, ci_p3, ci_p4, ci_p5, ci_p6;

        if (typeof (this.props.location.state) !== 'undefined' && this.props.location.state !== null) {

            resultats = this.props.location.state.resultatsArray;
            console.log(resultats)

        } else {
            resultats = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }

        for (let i = 0; i < 19; i++) {
            ci_global = ci_global + resultats[i];
        }

        //sum the global score from the resultats array by adding each results 
        ci_global = Math.round(ci_global);

        classes = `pie p${ci_global}`

        ci_p1 = Math.round((resultats[0] + resultats[1] + resultats[3]) * 8.33);
        finalResultats.push(ci_p1);

        ci_p2 = Math.round((resultats[4] + resultats[5] + resultats[6]) * 8.33);
        finalResultats.push(ci_p2);

        ci_p3 = Math.round((resultats[7] + resultats[8] + resultats[9] + resultats[10]) * 6.25);
        finalResultats.push(ci_p3);

        ci_p4 = Math.round((resultats[11] + resultats[12] + resultats[12]) * 8.33);
        finalResultats.push(ci_p4);

        ci_p5 = Math.round((resultats[13] + resultats[14] + resultats[15]) * 8.33);
        finalResultats.push(ci_p5);

        ci_p6 = Math.round((resultats[16] + resultats[17] + resultats[18]) * 8.33);
        finalResultats.push(ci_p6);

        const data = {
            labels: ['Expertise, Empathy & Authenticity', 'Scalability', 'Business Drive', 'Personalized engagement', 'Rich & User Friendly Messaging', 'Omnichannel and Seamlessness'],
            datasets: [
                {
                    label: '-',
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    pointBackgroundColor: 'transparent',
                    pointBorderColor: 'transparent',
                    pointHoverBackgroundColor: 'transparent',
                    pointHoverBorderColor: 'transparent',
                    data: [10, 30, 50, 80, 90, 100]
                },
                {
                    label: 'My Conversational Index',
                    backgroundColor: 'rgba(27, 199, 78, 0.4)',
                    borderColor: 'rgb(27, 199, 78)',
                    pointBackgroundColor: 'rgb(27, 199, 78)',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: 'rgb(27, 199, 78)',
                    data: finalResultats
                }
            ]
        };

        return (
            <>
                <SEO title="Conversational Maturity Index: Evaluate your Company's Conversational Maturity." image="https://info.iadvize.com/hubfs/CI%20-%202021/Thumbnail%20-%20Conversational%20Index%20(5).png" lang="en" description="By completing the online version of this audit, consisting of 19 simple questions, you will get an objective overview of your conversational maturity in less than 10 minutes." />
                <section className="resultatSection">
                    <div className="inner">

                        <img id="mainLogo" src="https://info.iadvize.com/hubfs/CI%20-%202021/Group%20879.png"></img>

                        <CiDonutsResultat data={ci_global} resultats={resultats} ci_p1={ci_p1} ci_p2={ci_p2} ci_p3={ci_p3} ci_p4={ci_p4} ci_p5={ci_p5} ci_p6={ci_p6} />

                        <div className="details">
                            <h2>Details</h2>
                            <div className="inner">

                                <div className="leftSideBloc">
                                    <div className="leftSide">
                                        <h3>Details of the answers</h3>
                                        <Radar className="radar" data={data} />
                                    </div>

                                    <CiCtaResultat data={ci_global} resultats={resultats} ci_p1={ci_p1} ci_p2={ci_p2} ci_p3={ci_p3} ci_p4={ci_p4} ci_p5={ci_p5} ci_p6={ci_p6} />


                                </div>

                                <div className="right">
                                    <div className="scoresInner">

                                        <div id="detailsH2">Your scores on the 6 pillars</div>

                                        <span>Best possible scores: 100</span>

                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Expertise-1.png"></img>
                                                <div className="legend">Expertise, Empathy & Authenticity</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p1}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Scalabilite%CC%81-1.png"></img>
                                                <div className="legend">Scalability</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p2}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Projet%20dentreprise-1.png"></img>
                                                <div className="legend">Business Drive</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p3}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Personnalisation-1.png"></img>
                                                <div className="legend">Personalized Engagement</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p4}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Messaging%20riche-1.png"></img>
                                                <div className="legend">Rich & User Friendly Messaging</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p5}
                                            </div>

                                        </div>
                                        <div className="line">

                                            <div className="left">
                                                <img src="https://info.iadvize.com/hubfs/CI%20-%202021/Omnicanal-1.png"></img>
                                                <div className="legend">Omnichannel and Seamlessness</div>
                                            </div>

                                            <div className="rightScore">
                                                {ci_p6}
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>



                </section>
                <Footer />
            </>
        )
    }

}

export default ResultatPageUs